<template>
  <div class="common-size-table__container">
    <div 
      :style="{height: `${maxHeight}px`}" 
      class="common-size-table__empty-container"
    ></div>
    <div 
      ref="tableContent"
      class="common-size-table"
    >
      <div class="common-size-table__top">
        <div class="common-size-table__top_title">
          {{ title }}
        </div>
        <div 
          v-if="tabs?.length"
          class="common-size-table__top_tabs"
        >
          <div
            v-for="item in tabs"
            :key="item.key"
            :class="[
              'common-size-table__top_tabs_item',
              { active: activeTab === item.key && !tabDisabled }
            ]"
            @click="handleClickTab(item)"
          >
            <span class="common-size-table__top_tabs_item_text">{{
              item.text
            }}</span>
            <span
              v-if="item.extra"
              class="common-size-table__top_tabs_item_extra"
            >{{ item.extra }}</span>
          </div>
        </div>
        <p
          v-if="desc"
          class="common-size-table__top_desc"
        >
          {{ desc }}
        </p>
      </div>
      <div 
        v-show="!hiddenTable"
        class="common-size-table__content"
        @scroll="handleScroll" 
      >
        <template v-if="slots['table-content']">
          <slot name="table-content"></slot>
        </template>
        <table
          v-else
          class="common-size-table__content_inner-table"
          cellspacing="0"
          cellpadding="0"
        >
          <!-- table header -->
          <thead>
            <tr v-if="tableHeader?.length > 0">
              <td
                v-for="(item, index) in tableHeader"
                :key="item"
                :class="{ 'common-size-table_td-shadow': index == 0 && showShadow }"
              >
                <template v-if="slots['table-header']">
                  <slot
                    name="table-header"
                    :value="item"
                    :index="index"
                  ></slot>
                </template>
                <template v-else>
                  {{ item }}
                </template>
              </td>
            </tr>
          </thead>
          <!-- table body -->
          <tbody>
            <tr
              v-for="(row, rowIndex) in tableData"
              :key="'table_row_' + rowIndex"
            >
              <td
                v-for="(col, colIndex) in row"
                :key="`row_${rowIndex}_col_${colIndex}`"
                :class="{ 'common-size-table_td-shadow': colIndex == 0 && showShadow }"
              >
                <!-- 单元格 -->
                <template v-if="slots['table-cell']">
                  <slot
                    name="table-cell"
                    :value="col"
                    :index="colIndex"
                  ></slot>
                </template>
                <template v-else>
                  {{ col }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="slots['table-bottom']"
        class="common-size-table__bottom"
      >
        <slot name="table-bottom"></slot>
      </div>
    </div>
    <div 
      class="common-size-table__empty" 
      :style="{height:`${addHeight}px`}"
    ></div>
  </div>
</template>

<script>
import { defineComponent, ref, nextTick, watch } from 'vue'
/**
 * @name CommonSizeTable
 * @description 公共尺码表格，只提供基础的样式和结构。
 * @description 不包含多余的业务逻辑，具体的业务逻辑由调用方实现，自行处理业务，转换数据，然后将转换后的数据结构传入该组件进行渲染
 */
export default defineComponent({
  name: 'CommonSizeTable',
  props: {
    title: {
      type: String,
      default: ''
    },
    /**
     * @description tabs 数据结构
     * @description { key: string, text: string, extra: string}[]
     */
    tabs: {
      type: Array,
      default: () => []
    },
    tabDisabled: {
      type: Boolean,
      default: false
    },
    desc: {
      type: String,
      default: ''
    },
    activeTab: {
      type: String,
      default: ''
    },
    /**
     * @description tableHeader 表头数据
     * @description { text: string; }[]
     */
    tableHeader: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    hiddenTable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['tab-change'],
  setup(props, { emit, slots }) {
    const maxHeight = ref(0)
    const addHeight = ref(0)
    const tableContent = ref(null)
    const showShadow = ref(false)
    watch(() => props.tableData, ()=>{
      nextTick(() => {
        requestAnimationFrame(() => {
          const clientHeight = tableContent.value.clientHeight || tableContent.value.offsetHeight
          maxHeight.value = clientHeight >  maxHeight.value ? clientHeight : maxHeight.value
          addHeight.value = maxHeight.value - clientHeight
        })
      })
    }, { immediate: true })
    function handleClickTab(tab) {
      if(props.tabDisabled) return
      emit('tab-change', tab)
    }
    function handleScroll(event) {
      showShadow.value = event.target.scrollLeft > 0
    }
    return { props, slots, handleClickTab, maxHeight, tableContent, addHeight, showShadow, handleScroll}
  }
})
</script>

<style lang="less">
.common-size-table {
  display: flex;
  width: 100%;
  padding: 0.32rem 0;
  flex-direction: column;
  align-items: flex-start;
  color: #000000;
  background-color: #ffffff;

  div:last-child {
    margin-bottom: 0;
  }

  &__top {
    margin-bottom: 0.32rem;
    display: flex;
    padding: 0 0.32rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    &_title {
      font-size: 0.37333rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.42667rem;
      /* 114.286% */
      text-transform: capitalize;
    }

    &_tabs {
      display: flex;
      align-items: center;
      align-self: stretch;
      overflow: hidden;

      &_item {
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
        flex-shrink: 1;
        display: flex;
        flex-wrap: nowrap;
        color: #767676;
        text-align: right;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 700;
        line-height: 0.42667rem;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 0.64rem; // 兼容低版本的ios

        &:last-child {
          margin-right: 0;
        }

        &:after {
          display: block;
          position: absolute;
          content: '';
          width: 100%;
          height: 0px;
          bottom: 0.21rem;
          background: #000000;
        }

        &_text {
          flex-shrink: 100;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &_extra {
          flex-shrink: 0;
          font-weight: 400;
        }
      }

      .active {
        color: #000000;
        position: relative;

        &:after {
          height: 3px;
          transition: height 0.3s;
        }

        .common-size-table__top_tabs_item_extra {
          font-weight: 700;
        }
      }
    }

    &_desc {
      color: #959595;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__container{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  &__empty-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
  }
  &__empty{
    position: relative;
    top: 0;
    left:0;
    width:100%;
    background: #f6f6f6;
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 0.32rem 0.32rem;;
    align-items: flex-start;
    align-self: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 0;

    &_inner-table {
      min-width: 100%;
      color: #000;
      text-align: center;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0;

      thead > tr td {
        height: 1.01333rem;
        font-weight: 700;
        padding: 0.16rem 0.21333rem;
        border-top: 1px solid #e5e5e5;
        background: #F6F6F6;
        overflow-wrap: break-word;
        white-space: normal !important;
      }

      td:first-child {
        position: sticky;
        left: 0;
        z-index: 5;
        padding: 0.16rem 0.21333rem;
        border-left: 1px solid #e5e5e5;
        background: #F6F6F6;
      }

      td {
        padding: 0.42667rem 0.21333rem;
        min-width: 1.28rem;
        box-sizing: border-box;
        background: #ffffff;
        border-right: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        white-space: nowrap;
      }

      tbody {
        td:first-child {
          padding: 0.4rem 0.21333rem;
        }
      }
    }
  }

  &_td-shadow {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -.4267rem;
      bottom: 0;
      width: .4267rem;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.00)0%, rgba(0, 0, 0, 0.05)100%);
    }
  }

  &__bottom {
    margin-bottom: 0.32rem;
    padding: 0 0.32rem;
  }
}
</style>
