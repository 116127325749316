import Vue from 'vue'
let compInstance = null
export default {
  open: (config) => {
    if (compInstance) {
      compInstance.open(config)
      return compInstance
    }
    return new Promise((resolve) => {
      import(/* webpackChunkName: "select_local_size" */ './main.vue').then((components) => {
        const selectLocalSize = components.default
        const vm = new Vue({
          render (h) {
            return h(selectLocalSize)
          }
        }).$mount()
        document.body.appendChild(vm.$el)
        const comp = vm.$children[0]
        comp.open(config)
        compInstance = comp
        resolve(compInstance)
      })
    })
  },
  close: () => {
    if (compInstance && compInstance.close) {
      compInstance.close()
    }
  },
}
